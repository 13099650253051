import { Box, ChakraProvider, extendTheme, Flex } from '@chakra-ui/react';
import { Dict } from '@chakra-ui/utils';
import React from 'react';

import frontendTheme from '../styles/frontendTheme';
import { ErrorPopup } from './ErrorPopup';
import FrontendFooter from './FrontendFooter';
import PageHeader from './FrontendHeader';

export const defaultSiteTheme = extendTheme({
  fonts: {
    body: 'Lato',
    header: 'Cormorant',
    heading: 'Cormorant',
  },
  components: {
    Heading: {
      baseStyle: {
        fontWeight: 'normal',
      },
    },
    Input: {
      variants: {
        filled: {
          field: {
            backgroundColor: frontendTheme.input.backgroundColor,
            _hover: {
              backgroundColor: frontendTheme.input.backgroundColor,
            },
            _focus: {
              backgroundColor: frontendTheme.input.focusedBackgroundColor,
            },
          },
          addon: {
            bg: frontendTheme.lightYellowBackground,
          },
        },
      },
    },
    NumberInput: {
      variants: {
        filled: {
          field: {
            backgroundColor: frontendTheme.input.backgroundColor,
            _hover: {
              backgroundColor: frontendTheme.input.backgroundColor,
            },
            _focus: {
              backgroundColor: frontendTheme.input.focusedBackgroundColor,
            },
          },
        },
      },
    },
    Textarea: {
      variants: {
        filled: {
          backgroundColor: frontendTheme.input.backgroundColor,
          _hover: {
            backgroundColor: frontendTheme.input.backgroundColor,
          },
          _focus: {
            backgroundColor: frontendTheme.input.focusedBackgroundColor,
          },
        },
      },
    },
    Radio: {
      variants: {
        filled: {
          control: {
            backgroundColor: frontendTheme.input.backgroundColor,
            borderColor: 'var(--chakra-colors-gray-400)',
            borderWidth: '1px',
          },
        },
      },
    },
    Checkbox: {
      variants: {
        filled: {
          control: {
            backgroundColor: frontendTheme.input.backgroundColor,
            borderColor: 'var(--chakra-colors-gray-400)',
            borderWidth: '1px',
          },
        },
      },
    },
  },
  styles: {
    global: {
      // styles for the `body`
      body: {
        bg: frontendTheme.lightYellowBackground,
      },
    },
  },
});

export const FrontendLayout = (props: {
  children: React.ReactNode;
  theme?: Dict<unknown> | undefined;
}): JSX.Element => {
  const theme = props.theme || defaultSiteTheme;
  return (
    <>
      <ChakraProvider theme={theme}>
        <Flex flexDirection="column" minHeight="100vh">
          <Box flexGrow={1}>
            <PageHeader />
            <ErrorPopup />

            {props.children}
          </Box>
          <Box flexGrow={0} flexShrink={0}>
            <FrontendFooter />
          </Box>
        </Flex>
      </ChakraProvider>
    </>
  );
};
