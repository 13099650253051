import { CSSObjectWithLabel } from 'react-select';

const frontendTheme = {
  lightYellowBackground: '#f3e6a4',
  headerFont: 'Cormorant',
  textColor: 'rgba(0,0,0,0.7)',
  primaryButtonBackground: '#533cff',
  input: {
    backgroundColor: '#fff9da',
    focusedBackgroundColor: '#ffe875',
    boxShadow: '0 1px 2px 0 rgb(0 0 0 / 10%)',
    padding: '0.75rem 0.5rem',
    fontSize: '1rem',
    lineHeight: '1.125rem',
    borderRadius: '2px',
  },
};

export default frontendTheme;

const inputBorderColor = 'var(--chakra-colors-transparent)';
const focusedInputBorderColor = 'rgb(49, 130, 206)';
const invalidInputBorderColor = 'rgb(229, 62, 62)';

const inputFontSize = 'var(--chakra-fontSizes-md)';
const inputBorderRadius = 'var(--chakra-radii-md)';
const inputHeight = ' var(--chakra-sizes-10)';

const inputPaddingInlineStart = 'var(--chakra-space-4)';
const inputPaddingInlineEnd = 'var(--chakra-space-4)';

interface SelectState {
  isFocused: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const chakraFrontendThemeReactSelectStyles = (hasError: boolean) => {
  return {
    control: (providedStyles: CSSObjectWithLabel, state: SelectState): CSSObjectWithLabel => ({
      ...providedStyles,
      boxSizing: 'border-box',
      backgroundColor: state.isFocused
        ? frontendTheme.input.focusedBackgroundColor
        : frontendTheme.input.backgroundColor,
      WebkitBoxShadow: 'none',
      boxShadow: 'none',
      borderStyle: 'solid',
      borderWidth: '2px',
      borderColor: state.isFocused
        ? focusedInputBorderColor
        : hasError
        ? invalidInputBorderColor
        : 'transparent',

      outline: 'none',
      padding: 0,
      color: 'inherit',
      fontSize: inputFontSize,
      borderRadius: inputBorderRadius,
      '&:hover': {
        borderColor: hasError ? invalidInputBorderColor : inputBorderColor,
      },
    }),
    valueContainer: (styles: CSSObjectWithLabel): CSSObjectWithLabel => ({
      ...styles,
      boxSizing: 'border-box',
      paddingInlineStart: inputPaddingInlineStart,
      paddingInlineEnd: inputPaddingInlineEnd,
      margin: 0,
      fontSize: inputFontSize,
      height: inputHeight,
      background: 'none',
      color: 'inherit',
    }),
    input: (styles: CSSObjectWithLabel): CSSObjectWithLabel => ({
      ...styles,
      boxSizing: 'border-box',
      padding: 0,
      margin: 0,
      fontSize: inputFontSize,
      height: inputHeight,
      color: 'inherit',
    }),
    placeholder: (styles: CSSObjectWithLabel): CSSObjectWithLabel => ({
      ...styles,
      padding: 0,
    }),
    dropdownIndicator: (styles: CSSObjectWithLabel): CSSObjectWithLabel => ({
      ...styles,
      color: 'inherit',
    }),
  };
};

const adminInputBackgroundColor = 'inherit';
const adminInputBorderColor = 'inherit';

const adminFocusedInputBoxShadow = 'none';
const adminFocusedInputBorderColor = '#3182ce';

const adminInvalidInputBoxShadow = '0 0 0 1px #e53e3e';
const adminInvalidInputBorderColor = '#e53e3e';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const chakraAdminThemeReactSelectStyles = (hasError: boolean) => {
  return {
    control: (providedStyles: CSSObjectWithLabel, state: SelectState): CSSObjectWithLabel => ({
      ...providedStyles,
      boxSizing: 'border-box',
      backgroundColor: adminInputBackgroundColor,
      WebkitBoxShadow: state.isFocused
        ? adminFocusedInputBoxShadow
        : hasError
        ? adminInvalidInputBoxShadow
        : 'none',
      boxShadow: state.isFocused
        ? adminFocusedInputBoxShadow
        : hasError
        ? adminInvalidInputBoxShadow
        : 'none',
      borderColor: state.isFocused
        ? adminFocusedInputBorderColor
        : hasError
        ? adminInvalidInputBorderColor
        : adminInputBorderColor,
      outline: 'none',
      padding: 0,
      color: 'inherit',
      fontSize: inputFontSize,
      borderRadius: 'var(--chakra-radii-md)',
      '&:hover': {
        borderColor: hasError ? adminInvalidInputBorderColor : adminInputBorderColor,
      },
    }),
    valueContainer: (styles: CSSObjectWithLabel): CSSObjectWithLabel => ({
      ...styles,
      boxSizing: 'border-box',
      paddingInlineStart: inputPaddingInlineStart,
      paddingInlineEnd: inputPaddingInlineEnd,
      margin: 0,
      fontSize: inputFontSize,
      height: inputHeight,
      background: 'none',
      color: 'inherit',
    }),
    input: (styles: CSSObjectWithLabel): CSSObjectWithLabel => ({
      ...styles,
      boxSizing: 'border-box',
      padding: 0,
      margin: 0,
      fontSize: inputFontSize,
      height: inputHeight,
      color: 'inherit',
    }),
    placeholder: (styles: CSSObjectWithLabel): CSSObjectWithLabel => ({
      ...styles,
      padding: 0,
    }),
    dropdownIndicator: (styles: CSSObjectWithLabel): CSSObjectWithLabel => ({
      ...styles,
      color: 'inherit',
    }),
  };
};
