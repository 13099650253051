import { UserProfile, useUser } from '@auth0/nextjs-auth0';
import {
  Avatar,
  Box,
  Button,
  Container,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
} from '@chakra-ui/react';
import axios from 'axios';
import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';

const NavItem = (props: { link: string; text: string }): JSX.Element => {
  return (
    <Box>
      <Link href={props.link}>
        <a>{props.text}</a>
      </Link>
    </Box>
  );
};

const MenuNavItem = (props: { link: string; text: string }): JSX.Element => {
  return (
    <Link href={props.link}>
      <MenuItem>{props.text}</MenuItem>
    </Link>
  );
};

const LoggedInUserMenuItem = ({ user }: { user: UserProfile }): JSX.Element => {
  const [stripeCustomerId, setStripeCustomerId] = useState<string>();

  useEffect(() => {
    async function fetchData(): Promise<void> {
      const result = await axios.get(`/api/stripe/customer_id`);
      setStripeCustomerId(result.data.stripeCustomerId);
    }
    fetchData();
  }, [user, setStripeCustomerId]);

  async function gotoBillingPortal(): Promise<void> {
    try {
      const result = await axios.get(
        `/api/stripe/customer_portal_url?returnUrl=${encodeURIComponent(
          window.location.toString()
        )}`
      );
      window.location = result.data.url;
    } catch (err) {
      if (err instanceof Error) {
        //setErrorMessage(err.message);
      } else {
        //setErrorMessage('Unknown error');
      }
    }
  }

  return (
    <Menu>
      <MenuButton>
        <Flex alignItems="center">
          <Box mr="4" color="purple">
            My Account
          </Box>
          <Avatar name={user.email || undefined} src={user.picture || undefined} />
        </Flex>
      </MenuButton>
      <MenuList>
        {stripeCustomerId && (
          <>
            <Link href="/payment_history">
              <MenuItem>Payment History</MenuItem>
            </Link>
            <MenuItem onClick={gotoBillingPortal}>Billing Portal</MenuItem>
          </>
        )}
        <Link href="/api/auth/logout">
          <MenuItem>Logout</MenuItem>
        </Link>
      </MenuList>
    </Menu>
  );
};

export default function PageHeader(): JSX.Element {
  const { user } = useUser();
  return (
    <Box py="4" bg="white" fontFamily="body">
      <Container maxW="container.lg">
        <Flex alignItems="center">
          <Box marginRight={4}>
            <Image src="/rtrfm_logo.png" alt="RTRFM Logo" width={100} height={45} />
          </Box>
          <Box flexGrow={1} display={{ base: 'flex', md: 'none' }}>
            <Menu>
              <MenuButton>Menu</MenuButton>
              <MenuList>
                <MenuNavItem link="/" text="Home" />
                <MenuNavItem link="/subscribe" text="Subscribe" />
                <MenuNavItem link="/subscribe/0" text="Donate" />
                <MenuNavItem link="/paymypledge" text="Pay Pledge" />
              </MenuList>
            </Menu>
          </Box>
          <Stack direction="row" flexGrow={1} spacing="6" display={{ base: 'none', md: 'flex' }}>
            <NavItem link="/" text="Home" />
            <NavItem link="/subscribe" text="Subscribe" />
            <NavItem link="/subscribe/0" text="Donate" />
            <NavItem link="/paymypledge" text="Pay Pledge" />
          </Stack>
          <Box flexGrow={0} flexShrink={1}>
            {user && <LoggedInUserMenuItem user={user} />}
            {!user && (
              <Link href="/api/auth/login">
                <Button variant="solid" colorScheme="purple">
                  My Account
                </Button>
              </Link>
            )}
          </Box>
        </Flex>
      </Container>
    </Box>
  );
}
